export enum FMFileStatus {
    Failed = 'failed',
    Success = 'success',
    Uploading = 'uploading',
}

export interface FMFile {
    id: number | null;
    index: number;
    status: FMFileStatus;
    url: string;
    meta: File;
}
