import jwtDecode from 'jwt-decode';
import url from 'url';
import { SessionToken } from './interfaces';

const REDIRECT_SITE = process.env.REACT_APP_REDIRECT_SITE;

export const parseToken = (
    onSuccess: (token: SessionToken) => void,
    onError: () => void
) => {
    const parsedUrl = url.parse(window.location.href, true);
    if (!parsedUrl.query.sessionToken && REDIRECT_SITE) window.location.assign(REDIRECT_SITE);
    try {
        const sessionTokenQuery = parsedUrl.query.sessionToken;
        if (sessionTokenQuery && typeof sessionTokenQuery === 'string') {
            const sessionToken: SessionToken = jwtDecode(sessionTokenQuery);
            if (sessionToken.accessToken) onSuccess(sessionToken);
        } else {
            throw new Error();
        }
    } catch (e) {
        console.error(e);
        onError();
    }
};
