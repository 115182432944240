import { useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Error.module.css';

export const Error = (props: { className: string }) => {
    const { t } = useTranslation();

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add(styles.errorBg);
        return () => {
            body.classList.remove(styles.errorBg);
        };
    }, []);

    return (
        <div className={cx(props.className, styles.error)}>
            <div className={styles.title}>{t('error')}</div>
            <div className={styles.subtitle}>{t('tryAgain')}</div>
        </div>
    );
};
