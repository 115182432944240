import { useState } from 'react';
import cx from 'classnames';
import { Loading3QuartersOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './Delete.module.css';
import { deleteFile } from '../../../../services/file';

interface Props {
    id: number | null;
    index: number;
    className: string;
    onDelete: (id: number) => void;
}

export const Delete = ({ id, index, className, onDelete }: Props) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const onDeleteFile = async () => {
        setIsDeleting(true);
        if (id) {
            await deleteFile(id);
        }
        onDelete(index);
        setIsDeleting(false);
    };

    return (
        <div onClick={onDeleteFile} className={cx(className, styles.button)}>
            {!isDeleting && <DeleteOutlined className={styles.icon} />}
            {isDeleting && (
                <Loading3QuartersOutlined spin className={styles.icon} />
            )}
        </div>
    );
};
